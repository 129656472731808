import React from "react";
import ChatMessage from "./ChatMessage";
const AnswerSection = ({ messages, isLoading }) => {
    return (
        <>
            <div className="answer-container">
                {
                    messages.map((message, index) => {
                        return (
                            <ChatMessage message={message.content} role={message.role} key={index} />
                        );
                    })
                }
            </div>
            <hr className="hr-line" />
        </>
    )
}

export default AnswerSection;