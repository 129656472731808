import React, { useState } from "react";
import FormSection from './components/FormSection';
import AnswerSection from './components/AnswerSection';
import DebugSection from './components/DebugSection';
import { Configuration, OpenAIApi } from 'openai';

const App = () => {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const [filteredMessages, setFilteredMessages] = useState([]);
  const [verifiableMessages, setVerifiableMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [petProfileProps, setPetProfileProps] = useState([]);

  const [requestError, setRequestError] = useState(false);

  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: `You are a Veterinary Bot, an automated service to try to diagnose and recommend steps to follow for pet owners who are seeking care for their pets.
      You first greet the owner, then collects the necessary information about their pets and finally you try to diagnose what's going on with them.
      You wait to collect all the relevant data about the pet and how they feel. 
      The data you need to collect are the keys of this json object {"pet_type": "", "name": "", "breed": "", "gender": "", "age": "", "weight": "", "symptom": ""}. 
      Each of these pieces of information needs to be asked on its own step and using a human readable format.
      On every response, you try to infer all the information necessary to build the json object. You only ask for what you were not able to figure out from the conversation.
      If any of these pieces of information can be inferred from the conversation, you skip the question about it and continue to the next.
      Once you are given the name, you start referring to the pet with their name.
      Once you have all the necessary data, you ask about the problem.
      Every message you receive will follow the pattern within backticks \`{{{content}}}{{{instructions}}}\`
      Your response will always be a json object
      `
    }
  ]);

  // Mejorar la UI

  const handleApproval = (answer, isApproved) => {
    answer.content.is_approved = isApproved;
    answer.content.is_reviewed = true;
    processAnswer(answer.content);
  }

  const processAnswer = (answer, filteredAnswers) => {
    if (!filteredAnswers) {
      filteredAnswers = filteredMessages;
    }
    if (!answer.is_recommendation || answer.is_reviewed) {
      setFilteredMessages([
        ...filteredAnswers,
        {
          role: 'assistant',
          content: answer
        }
      ]);
      setIsLoading(false);
    } else if (answer.is_recommendation) {
      setVerifiableMessages([
        ...verifiableMessages,
        {
          role: 'assistant',
          content: answer
        }
      ]);
    }
    
    answer.info && setPetProfileProps(Object.keys(answer.info).map((key) => ({ name: key, value: answer.info[key] })));
  };

  const generateResponse = async (newQuestion, setNewQuestion) => {
    setRequestError(false);
    newQuestion = newQuestion.trim();
    if (newQuestion === '') {
      return false;
    }

    setIsLoading(true);

    let options = {
      model: 'gpt-4',
      temperature: 0.8,
      max_tokens: 1000,
      top_p: 1,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
      stop: ['/'],
    };

    const messagesToSend = [
      ...messages,
      {
        role: 'user',
        content: `{{{${newQuestion}}}}{{{Try to guess pet's name, age, weight, gender, type, breed and symptom from the message. Respond with a json object respecting the pattern within backticks: \`{"answer": <insert your answer here>, "is_recommendation": a boolean value depending on if your answer is a recommendation or not, "info": a json object containing the info you gathered so far, "is_complete": when the conversation is over}\`}}}`
      }
    ];

    let completeOptions = {
      ...options,
      messages: messagesToSend,
    };

    const filteredAnswers = [
      ...filteredMessages,
      {
        role: 'user',
        content: newQuestion
      }
    ];

    // filteredAnswers.push({
    //   role: 'assistant',
    //   content: {
    //     answer: 'I am a Veterinary Bot, an automated service to try to diagnose and recommend steps to follow for pet owners who are seeking care for their pets.',
    //     is_recommendation: false
    //   }
    // });
    // filteredAnswers.push({
    //   role: 'assistant',
    //   content: {
    //     answer: Math.random(),
    //     is_recommendation: true
    //   }
    // });

    setMessages(messagesToSend);

    setFilteredMessages(filteredAnswers)

    const response = await openai.createChatCompletion(completeOptions);

    if (response.data.choices) {
      try {          
        setNewQuestion('');
        setMessages([
          ...messagesToSend,
          {
            role: 'assistant',
            content: response.data.choices[0].message.content
          }
        ]);
        
        const answer = JSON.parse(response.data.choices[0].message.content);

        processAnswer(answer, filteredAnswers);
      } catch (e) {
        console.log(e);
        setRequestError(true);
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      <div className="header-section">
        <h1>VetBot 🤖🐶🐱🐴</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h2>User Chat</h2>
          </div>
          <div className="col-6">
            <h2>Vet's View</h2>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-6">
            <AnswerSection messages={filteredMessages} isLoading={isLoading} />
            <FormSection generateResponse={generateResponse} isLoading={isLoading} />
            {requestError && <p className="text-center">An error occurred, please try again.</p>}
          </div>
          <div className="col-6 form-section">
            <div>
              { verifiableMessages.map( (message, i) => {
                return (
                  <div className="vet-answer" key={i}>
                    <p className="bg-white text-black p-3">
                      { message.content.answer }
                      { message.content.is_reviewed && message.content.is_approved && <><br /><span className="text-success">Approved</span></> }
                      { message.content.is_reviewed && !message.content.is_approved && <><br /><span className="text-danger">Rejected</span></> }
                    </p>
                    { 
                      !message.content.is_reviewed && 
                      <div className="d-flex justify-content-between">
                        <div className="col-6 pe-2">
                          <button className="btn btn-danger" onClick={() => { handleApproval(message, 0) }}>Reject</button>
                        </div>
                        <div className="col-6 ps-2">
                          <button className="btn btn-success" onClick={() => { handleApproval(message, 1) }}>Approve</button>
                        </div>
                      </div>
                    }
                    
                  </div>
                )
              } ) }
            </div>
          </div>
        </div>
        <div className="row">
          <hr />
          <div className="col-6">
            <h2>Debugger</h2>
            <DebugSection messages={messages} />
          </div>
          <div className="col-6">
            <h2>Pet Profile</h2>
            <table className="table text-white">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {
                  petProfileProps.map((prop, i) => (
                    <tr key={i}>
                      <td> { prop.name } </td>
                      <td> { prop.value } </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;