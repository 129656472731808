import React from "react";
const DebugSection = ({ messages }) => {
    return (
        <>
            <div className="debug-container">
                {
                    messages.map((message, index) => {
                        return (
                            <div className="" key={index}>
                                <p>
                                    <strong className={`${message.role === 'system' ? 'text-danger' : message.role === 'user' ? 'text-info' : 'text-success' } text-capitalize`}>{ message.role }: </strong>
                                    { message.content }
                                </p>
                            </div>
                        );
                    })
                }
            </div>
            <hr className="hr-line" />
        </>
    )
}

export default DebugSection;