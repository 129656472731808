import React, { useState } from "react";

const FormSection = ({ generateResponse, isLoading }) => {
    const [newQuestion, setNewQuestion] = useState('');

    const submitGenerateResponse = (e) => {
        generateResponse(newQuestion, setNewQuestion);
        setNewQuestion('');
    }

    const generateResponseIfIsEnterKey = (e) => {
        if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey && !e.altKey) {
            e.preventDefault();
            e.stopPropagation();
            submitGenerateResponse();
        }
    }

    return (
        <div className="form-section">
            <textarea
                rows="5"
                className="form-control"
                placeholder="Ask me anything..."
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                onKeyUp={ generateResponseIfIsEnterKey }
            ></textarea>
            <button className={`btn btn-primary ${isLoading ? 'btn-disabled' : ''}`} onClick={ submitGenerateResponse } disabled={isLoading} >
                { isLoading ? <span className="loader"></span> : 'Ask Me'}  
            </button>
        </div>
    )
}

export default FormSection;