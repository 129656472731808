import React from 'react';

const ChatMessage = ( {message, role} ) => {
    return (
        <>
            <div className={`chat-message mb-2 ${role === 'user' ? 'text-left' : 'text-end'}`}>
                { 
                    typeof message === 'object' 
                    ?   
                        <p className="question bg-white text-black p-3 ">
                            <span className="h4">🤖</span><br/>
                            {message.answer}
                            {
                                message.is_recommendation && message.is_reviewed && 
                                <>
                                    <br/>
                                     { 
                                       message.is_approved 
                                       ? <span className="text-success">Approved by <a className="text-succes" href="#">John Doe</a></span>
                                       : <span className="text-danger">We were not able to validate this answer with a vet</span>
                                     }
                                </>
                            }
                        </p>
                    :   <p className="question bg-white text-black p-3 ">
                            <span className="h4">👦🏻</span><br/>
                            {message}
                        </p>
                }

                
            </div>
        </>
        );
    };
    
    export default ChatMessage;